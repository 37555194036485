@media (max-width: 1600px) {
}
@media (max-width: 1440px) {
}
@media (max-width: 1300px) {
}
@media (max-width: 1240px) {
}
@media (max-width: 1199px) {
  .browse-courses {
    white-space: nowrap;
  }
  ul.navbar_dropdown {
    padding: 10px;
    width: 240px;
  }
  ul.navbar_dropdown li a {
    line-height: 20px;
  }
  .navbar_link ul li a {
    padding: 5px 0 !important;
  }
  .edu-banner-row {
    height: 520px;
  }
  .web_card {
    padding: 15px 10px;
  }
  .edu-banner-left {
    padding: 0 15px;
  }
  .edu-banner-title {
    font-size: 35px;
    line-height: 42px;
  }
  .edu-banner-btn {
    font-size: 18px;
    padding: 10px 30px;
  }
  .card_list {
    width: 83%;
  }
  .card_filters {
    width: 15%;
  }
  .card_list ul {
    width: 100%;
    overflow-x: scroll;
  }
  .card_filters .filter_btn img.filter {
    margin-left: 5px;
    width: 15px;
  }
  .card_filters .filter_btn {
    font-size: 12px;
    padding: 10px 15px;
  }
  .card_list ul li a {
    font-size: 15px;
    padding: 12px 10px;
  }
  .graysection {
    padding: 30px 10px;
  }
  .graysection_title {
    width: 90%;
  }
  .graysection_title h2 {
    font-size: 28px;
    line-height: 26px;
  }
  .getmee_card_text h4 {
    font-size: 16px;
  }
  .getmee_card_text p {
    font-size: 14px;
  }

  .getmee_form_section {
    padding: 60px 40px;
  }
  .getmee_form_title h2 {
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .getmee_form_form {
    width: 80%;
  }
  .getmee_ff_info {
    width: 90%;
  }
  .getmee_ff_info p {
    font-size: 14px;
  }
  .getmee_ff_tab {
    margin-bottom: 10px;
  }
  /* filter */
  .filter_site {
    width: 50%;
  }
  .filter_title h2 {
    font-size: 26px;
  }
  .filter_title p {
    font-size: 16px;
  }
  .fc_title h4 {
    font-size: 20px;
  }
  .fc_tab ul li label.text {
    font-size: 15px;
  }
  .filter_bottom a.fb_reset {
    font-size: 15px;
    padding: 5px 30px;
  }
  .filter_bottom a.fb_apply {
    font-size: 15px;
    padding: 5px 30px;
  }

  .course-detail {
    padding: 15px 0;
  }
  .course-detail .breadcrumbs {
    margin-left: 10px;
  }
  .course-detail .breadcrumbs ul li {
    font-size: 13px;
  }
  .course-detail .breadcrumbs .smallarrowright {
    margin: 0 5px;
  }
  .course-detail-left {
    padding: 0 10px;
  }
  .course-detail-title {
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 7px 0;
  }
  .course-detail-left h5 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }
  .course-detail-left p {
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0;
  }
  .course-detail-left .cd-price {
    font-size: 24px;
    margin: 10px 0;
  }
  .course-detail-left .course-detail-btn {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 8px 40px;
    font-size: 18px;
  }
  .course-detail-left .course-detail-btn .smalltext {
    font-size: 14px;
  }

  .course-detail-view {
    padding: 0 10px !important;
  }
  .course-detail-view .course_list {
    margin-bottom: 10px;
  }
  .course-detail-view .course_list ul li a {
    font-size: 15px;
    padding: 10px 20px;
  }
  .course-detail-view .how-it-works-img {
    display: inline;
  }
  .course-detail-view .course-description h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .course-detail-view .course-description p {
    font-size: 14px;
    line-height: 24px;
  }
  .how_it_works label.text {
    font-size: 16px;
  }
  .getmee_card {
    min-height: 410px;
  }

  .step2 .popup_middle {
    padding: 30px;
  }
  .popup_result {
    width: 80%;
  }
  .popup_result h4 {
    font-size: 24px;
    line-height: 34px;
    margin: 20px 0;
  }

  .holiday_study {
    padding: 35px 0;
  }
  .holiday_study h3 {
    font-size: 24px;
  }
  .holiday_study button.holiday_study_btn {
    font-size: 18px;
    padding: 8px 30px;
    margin-top: 15px;
    float: none;
  }
  .holiday_study button.holiday_study_btn span.smalltext {
    font-size: 14px;
  }

  .youstudy-international {
    padding: 35px 0;
  }
  .youstudy-logo img.youstudy {
    height: 60px;
  }
  .youstudy-international .breadcrumbs-col {
    margin-bottom: 20px;
  }
  .youstudy-international .breadcrumbs {
    margin-left: 40px;
  }
  .youstudy-logo {
    margin-left: 40px;
  }
  .youstudy-international-left {
    margin: 0 40px;
  }
  .youstudy-international-left h2.youstudy-title {
    font-size: 32px;
    line-height: 38px;
    margin-top: 10px;
  }
  .youstudy-international-left p {
    font-size: 14px;
    line-height: 24px;
  }
  .youstudy-international-right img {
    width: 100%;
  }
  .institute_question_section button.holiday_study_btn {
    float: right;
  }
  .footer_social ul li a {
    width: 30px;
    height: 30px;
    padding: 0;
  }
  .footer_social ul li {
    margin: 0 4px 4px 4px;
  }
}
@media (max-width: 991px) {
  .edu-banner-row {
    height: 420px;
  }
  .getmee_form_section {
    padding: 50px 15px;
  }
  .getmee_form_title h2 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .getmee_form_form {
    width: 95%;
  }
  .getmee_ff_info {
    width: 95%;
  }
  .getmee_ff_tab input {
    font-size: 15px;
    height: 45px;
    padding: 5px 0;
  }
  .get_started .btn-edu-primary {
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 600px) {
  .smloader {
    left: 40%;
  }
  .navbar-brand-box {
    display: block;
  }
  .navbar-header {
    padding: 0;
    display: none;
  }
  .navbar_link {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
  .navbar {
    padding: 0.5rem 0;
  }
  #page-topbar .btn-edu-primary {
    font-size: 12px;
    padding: 7px 15px;
    white-space: nowrap;
  }
  .edu-banner-row {
    height: 350px;
  }
  .edu-banner-row .col-lg-6:nth-child(2) {
    display: none;
  }
  .edu-banner-title {
    font-size: 30px;
    line-height: 36px;
  }
  .edu-banner-btn {
    font-size: 15px;
    padding: 8px 22px;
  }
  .card_list {
    width: 75%;
  }
  .card_filters {
    width: 25%;
  }
  .card_list ul {
    width: 100%;
    overflow-x: scroll;
  }
  .card_filters .filter_btn img.filter {
    margin-left: 5px;
    width: 15px;
  }
  .card_filters .filter_btn {
    font-size: 12px;
    padding: 10px 15px;
  }
  /* filter */
  .filter_site {
    width: 92%;
  }
  .filter_title h2 {
    font-size: 24px;
  }
  .filter_title p {
    font-size: 14px;
  }
  .fc_title h4 {
    font-size: 18px;
  }
  .fc_tab ul li label.text {
    font-size: 13px;
  }
  .filter_bottom a.fb_reset {
    font-size: 13px;
    padding: 5px 20px;
  }
  .filter_bottom a.fb_apply {
    font-size: 13px;
    padding: 5px 20px;
  }

  .web_card {
    padding: 15px 10px;
  }
  .edu-banner-left {
    padding: 0 15px;
  }
  .edu-banner-logo {
    max-width: 200px;
  }
  .card_list ul li a {
    font-size: 12px;
    padding: 12px 5px;
  }
  .graysection {
    padding: 20px 10px;
  }
  .graysection_title {
    width: 90%;
  }
  .graysection_title h2 {
    font-size: 22px;
    line-height: 26px;
  }
  .getmee_card_text h4 {
    font-size: 14px;
  }
  .getmee_card_text p {
    font-size: 12px;
  }

  .getmee_form_section {
    padding: 50px 15px;
  }
  .getmee_form_title h2 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .getmee_form_form {
    width: 95%;
  }
  .getmee_ff_info {
    width: 95%;
  }
  .getmee_ff_info p {
    font-size: 14px;
  }
  .getmee_ff_tab {
    margin-bottom: 10px;
  }
  .getmee_ff_tab input {
    font-size: 15px;
    height: 45px;
    padding: 5px 0;
  }

  .footer-marketplace {
    padding: 30px 0 30px 0;
  }
  .footer_social {
    margin-bottom: 15px;
  }
  .footer_tab h3 {
    margin: 0 0 15px 0;
  }

  /* course details */
  .course-detail {
    padding: 15px 0 0 0;
  }
  .course-detail .col-lg-6:nth-child(2) {
    display: none;
  }
  .course-detail .breadcrumbs {
    margin-left: 10px;
  }
  .course-detail .breadcrumbs ul li {
    font-size: 12px;
  }
  .course-detail .breadcrumbs .smallarrowright {
    margin: 0 5px;
  }
  .course-detail-left {
    padding: 0 10px;
  }
  .course-detail-title {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 7px 0;
  }
  .course-detail-left h5 {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
  .course-detail-left p {
    font-size: 13px;
    line-height: 20px;
    margin: 10px 0;
  }
  .course-detail-left .cd-price {
    font-size: 20px;
    margin: 10px 0;
  }
  .course-detail-left .course-detail-btn {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 8px 40px;
    font-size: 15px;
  }
  .course-detail-left .course-detail-btn .smalltext {
    font-size: 13px;
  }
  .course-detail-view {
    padding: 0 10px !important;
  }
  .course-detail-view .course_list {
    margin-bottom: 10px;
  }
  .course-detail-view .course_list ul li a {
    font-size: 13px;
    padding: 10px 15px;
  }
  .course-detail-view .course_list ul {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .course-detail-view .course-description {
    padding: 10px;
  }
  .course-detail-view .course-description h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .course-detail-view .course-description p {
    font-size: 13px;
    line-height: 22px;
  }
  .how_it_works label.text {
    font-size: 15px;
  }
  .course-detail-view .how_it_works ul {
    display: block;
  }
  .course-detail-view .how_it_works ul li:not(:first-child) {
    margin-top: 35px;
  }
  .course-detail-view .how-it-works-img {
    width: 100%;
    display: block;
  }
  .course-detail-view .how_it_works img.arrowright {
    transform: rotate(90deg);
    height: 10px;
    position: relative;
    text-align: center;
  }
  .course-detail-view .user_profile_img {
    width: 175px;
  }
  .course-detail-view .user_profile_text h4 {
    font-size: 18px;
  }
  .step2 .popup_middle {
    padding: 25px;
  }
  .popup_result {
    width: 90%;
  }
  .popup_result h4 {
    font-size: 20px;
    line-height: 28px;
    margin: 20px 0;
  }
  .popup_result .btn_ok {
    font-size: 16px;
  }

  .web_review {
    margin-top: 25px;
  }
  .review_tab {
    padding: 15px 10px;
    min-height: 320px;
  }
  .review_title {
    margin-bottom: 15px;
  }
  .review_title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .review_title span.smalltext {
    font-size: 14px;
  }
  .review_title span.style-module_starRatingWrap__q-lJC {
    margin-top: -9px;
  }
  .review_tab .review_tab_text h4 {
    font-size: 18px;
  }
  .review_tab .review_tab_text p {
    font-size: 13px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 14px !important;
  }
  .review_button {
    margin-bottom: 25px;
  }

  .holiday_study {
    padding: 35px 0;
    text-align: center;
  }
  .holiday_study h3 {
    font-size: 22px;
  }
  .holiday_study p {
    font-size: 14px;
  }
  .holiday_study button.holiday_study_btn {
    font-size: 18px;
    padding: 8px 30px;
    margin-top: 15px;
    float: none;
  }
  .holiday_study button.holiday_study_btn span.smalltext {
    font-size: 14px;
  }
  /* popup */
  .popup_left {
    padding: 10px 20px;
  }
  .popup_card_logo {
    margin-bottom: 5px;
  }
  .popup_card_price label {
    margin: 0;
  }
  .popup_card_price p {
    font-size: 30px;
  }
  .popup_card_details {
    margin-top: 10px;
  }
  .popup_info {
    margin-top: 7px;
  }
  .popup_right {
    padding: 10px;
  }
  .popup_tab {
    margin-bottom: 10px;
  }

  .youstudy-international {
    padding: 15px 0;
  }
  .youstudy-international .breadcrumbs-col {
    margin-bottom: 10px;
  }
  .youstudy-international .breadcrumbs,
  .youstudy-logo,
  .youstudy-international-left {
    margin-left: 0;
  }
  .youstudy-international .breadcrumbs ul li {
    font-size: 12px;
  }
  .youstudy-international .col-lg-5:nth-child(2) {
    display: none;
  }
  .youstudy-international-left h2.youstudy-title {
    font-size: 22px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .institute_question_section button.holiday_study_btn {
    float: none;
  }

  .navbar-toggler {
    font-size: 24px;
  }
  .dropdown-toggle i {
    float: right;
    margin-top: 5px;
  }
  .navbar-brand {
    margin-right: 5px;
    margin-left: 15px;
  }
  .gt-search-form-autocomplete-group-course-img {
    margin-right: 0.5rem;
  }
  .gt-search-form-autocomplete-suggestion-block-list-item {
    padding-top: 10px;
  }

  /* show */
}
