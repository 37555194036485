//
// Fonts
//

// Nunito - Google Font
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../../fonts/muli-light.ttf") format("truetype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../../fonts/muli.ttf") format("truetype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/muli-semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../../fonts/muli-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../../fonts/muli-extrabold.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik Regular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../../fonts/GraphikRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik Medium";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../../fonts/GraphikMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik Semibold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../../fonts/GraphikSemibold.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik Bold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../../fonts/GraphikBold.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik Black";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../../../fonts/GraphikBlack.ttf") format("truetype");
}
