// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    left: $sidebar-width;
    height: $footer-height;
    box-shadow: $box-shadow;
    background-color: $footer-bg;
}
.footer-marketplace {
    background-color: $footer-bg;
    padding: 80px 0 30px 0;
}
.footer_logo {}
.footer_logo p { margin: 15px 0;}
.footer_tab {}

.footer_tab h3 {
    font-size: 18px;
    color: #032529;
    font-family: "Graphik Semibold";
    margin: 0 0 30px 0;
}

.footer_tab ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer_tab ul li {display: block;margin: 8px 0;}

.footer_tab ul li a {
    font-size: 14px;
    font-family: "Graphik Regular";
    color: #032529;
}

.app_icons {}

.app_icons a {
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
}
.copy {
    text-align: center;
    border-top: 1px solid #f2f2f2;
    padding: 20px 0 0;
    color: #03252980;
    font-family: "Graphik Regular";
}
.copy span {
    color: #038389;
}
.footer_social {}

.footer_social ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer_social ul li {
    padding: 0;
    display: inline-block;
    margin: 0 6px;
}

.footer_social ul li a {
    display: block;
    width: 36px;
    height: 36px;    
    border: 1px solid #e1e1e1;
    border-radius: 100%;
    text-align: center;
    padding: 4px 0;
}

.footer_social ul li a img {
    
}

@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}